const vi_delete_all_icon: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='58' height='58' viewBox='0 0 58 58' fill='none'>
			<circle cx='29' cy='29' r='29' fill='#FFEDEF' />

			<svg x='13' y='13' width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M9.33203 28C8.5987 28 7.97114 27.7391 7.44936 27.2173C6.9267 26.6947 6.66536 26.0667 6.66536 25.3333V8H5.33203V5.33333H11.9987V4H19.9987V5.33333H26.6654V8H25.332V25.3333C25.332 26.0667 25.0711 26.6947 24.5494 27.2173C24.0267 27.7391 23.3987 28 22.6654 28H9.33203ZM22.6654 8H9.33203V25.3333H22.6654V8ZM11.9987 22.6667H14.6654V10.6667H11.9987V22.6667ZM17.332 22.6667H19.9987V10.6667H17.332V22.6667Z'
					fill='#FD4F4F'
				/>
			</svg>
		</svg>
	);
};
export default vi_delete_all_icon;
