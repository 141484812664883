import { omitBy } from 'lodash';
import querystring from 'qs';
import parser from 'ua-parser-js';
import { AUTH } from './apiConstrant';
import { API_BASE_URL, IS_PRODUCTION, userStorageName } from './config';

type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface FetchOptions {
	method?: FetchMethod;
	body?: any;
	headers?: Record<string, string>;
	cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
}

interface FetchResponse<T> {
	status: string;
	data: T | null | undefined | any;
	error?: string;
	message?: string;
	route?: string;
	isLoading?: boolean;
	isError?: boolean;
	mutate?: any;
	refetchWithNewData?: any;
}
const handleResponse = async (url: string, options: FetchOptions, user: any): Promise<any> => {
	if (user?.authToken) {
		try {
			const response = await fetch(`${API_BASE_URL}${AUTH.TOKEN_REFRESH}`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					Authorization: `Bearer ${user.authToken}`,
					...options.headers
				},
				next: { revalidate: 60 * 10 }
			});

			const { data, status } = await response.json();
			if (status === 'success') {
				const newUser = {
					...user,
					authToken: data?.authToken
				};
				localStorage.setItem(userStorageName, JSON.stringify(newUser));
				return await AroggaAPI(url, options, true);
			}
			localStorage.removeItem(userStorageName);
		} catch (error) {
			throw new Error('Refresh token failed ! Please login again');
		}
	}
};
export const getLocalStorage = (key) => {
	return typeof window !== 'undefined' && localStorage.getItem(key)
		? JSON.parse(localStorage.getItem(key) as string)
		: null;
};
function getUrl(url: string, params = {} as object) {
	const [baseUrl, queryString] = url.split('?');
	const existingParams = querystring.parse(queryString);
	const mergedParams = {
		...existingParams,
		...params
	};
	const newQueryString = querystring.stringify(mergedParams);
	return `${baseUrl}?${newQueryString}`;
}

const AroggaAPI = async <T>(
	url: string,
	options: FetchOptions = {} as object,
	isFullUrl = false
): Promise<FetchResponse<T>> => {
	const headers: Record<string, string> = {
		'Content-Type': 'application/json',
		...options.headers
	};
	options.method = options.method || 'GET';
	const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
	const userTest = getData && JSON.parse(getData);
	const accessToken = userTest?.authToken || null;
	const authHeaders: Record<string, string> = accessToken
		? { Authorization: `Bearer ${accessToken}` }
		: ({} as Record<string, string>);
	url = isFullUrl ? url : `${API_BASE_URL}${url}`;
	const { browser, os } = parser();

	const localthirdPartyRef = getLocalStorage('thirdPartyRef');

	const params = {
		f: ['iPhone', 'iPod', 'iPad', 'Android', 'BlackBerry', 'Mobile'].includes(os?.name || '') ? 'mweb' : 'web',
		b: browser?.name || '',
		v: browser?.version || '',
		os: os?.name || '',
		osv: os?.version || '',
		...(localthirdPartyRef ? { refPartner: localthirdPartyRef.refPartner } : {})
	};

	options.body = omitBy(options.body, (value) => value == null);

	const formData = typeof FormData !== 'undefined' ? new FormData() : (null as any);
	if (options.method === 'GET') {
		if (options.body) {
			url = getUrl(url, options.body);
			delete options.body;
		}
	}

	if (options.method === 'DELETE') {
		if (options.body) {
			url = getUrl(url, options.body);
			delete options.body;
		}
	}

	url = getUrl(url, params);

	for (const key in options.body) {
		if (Array.isArray(options.body[key])) {
			options.body[key].forEach((item: any) => {
				formData.append(key, item);
			});
		} else {
			formData.append(key, options.body[key]);
		}
		delete headers['Content-Type'];
	}

	try {
		const response = await fetch(url, {
			method: options.method || 'GET',
			credentials: 'include',
			headers: {
				...headers,
				...authHeaders
			},
			body: options.body ? formData : undefined,
			next: { revalidate: +process.env.REVALIDATE_TIME || 60 * 10 }
			// ...(!IS_PRODUCTION
			// 	? { cache: "no-store" }
			// 	: {
			// 			next: { revalidate: 60 * 10 },
			// 	  }),
			// next: { revalidate: 60 * 10 },
			// cache: "no-store",
		});

		const responseData = await response.json();
		if (!response.ok) {
			if (response.status === 401 || response.status === 403) {
				return handleResponse(url, options, userTest);
			}
			return {
				status: 'fail',
				data: null,
				message: responseData?.message
			};
		}
		return responseData as FetchResponse<T>;
	} catch (err) {
		if (err instanceof Error) {
			return {
				status: 'fail',
				data: null,
				message: err.message,
				route: url
			};
		}
		return {
			status: 'fail',
			data: null,
			message: 'Something went wrong',
			route: url
		};
	}
};

AroggaAPI.get = <T>(url: string, body?: object) => AroggaAPI<T>(url, { method: 'GET', body });

AroggaAPI.post = <T>(url: string, body?: object) => AroggaAPI<T>(url, { method: 'POST', body });

AroggaAPI.put = <T>(url: string, body?: object) => AroggaAPI<T>(url, { method: 'PUT', body });

AroggaAPI.delete = <T>(url: string, body?: object) => AroggaAPI<T>(url, { method: 'DELETE', body });

export default AroggaAPI;
