const v1_bulk_add_to_favourites_icon: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='58' height='58' viewBox='0 0 58 58' fill='none'>
			<circle cx='29' cy='29' r='29' fill='#FFEDEF' />

			<svg x='13' y='13' width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M16.1346 24.5003L16.0013 24.6336L15.8546 24.5003C9.5213 18.7536 5.33464 14.9536 5.33464 11.1003C5.33464 8.43363 7.33464 6.43363 10.0013 6.43363C12.0546 6.43363 14.0546 7.76697 14.7613 9.5803H17.2413C17.948 7.76697 19.948 6.43363 22.0013 6.43363C24.668 6.43363 26.668 8.43363 26.668 11.1003C26.668 14.9536 22.4813 18.7536 16.1346 24.5003ZM22.0013 3.76697C19.6813 3.76697 17.4546 4.84697 16.0013 6.5403C14.548 4.84697 12.3213 3.76697 10.0013 3.76697C5.89464 3.76697 2.66797 6.9803 2.66797 11.1003C2.66797 16.127 7.2013 20.247 14.068 26.4736L16.0013 28.2336L17.9346 26.4736C24.8013 20.247 29.3346 16.127 29.3346 11.1003C29.3346 6.9803 26.108 3.76697 22.0013 3.76697Z'
					fill='#FD4F4F'
				/>
			</svg>
		</svg>
	);
};
export default v1_bulk_add_to_favourites_icon;
